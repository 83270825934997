import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor } from '@/utils/themes'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Section from '@/components/common/Section'
import ParticleBackground from '@/components/common/ParticleBackground'
import Typography from '@/components/common/Typography'

const CompanyCSV = ({ theme, heading, html, ...other }) => (
  <Root {...other}>
    <Section
      subtitle="CSV"
      title="地域活動"
      titleProps={{
        variant: 'h3'
      }}
      subTitleProps={{
        lang: 'en'
      }}
    >
      <Container theme={theme}>
        <Head>
          <Background scale={400} />
          <Lead variant="body1">
            ウィザードは地域の活動を通し、近隣のみなさまのお役に立つことが大事と考えます。<br />
            遠方のシステム会社にご相談する前に、是非一度ウィザードにご相談ください。<br />
            これまで培った経験と技術を近隣のみなさまに還元したいと考えています。
          </Lead>
        </Head>
        <Main>
          <List>
            <List_Parent>
              <List_Child>
                <Item>
                  <Item_Title variant="h4">本社（神奈川県相模原市）</Item_Title>
                  <Item_Body theme={theme} variant="body2">ウィザード本社では、近隣の専門学校、大学からのインターンシップの受け入れ及び求人を通し、地域の雇用促進に貢献しています。今後は、ITの地産地消を目指し、近隣のみなさまから頼りにされる企業を目指しています。</Item_Body>
                </Item>
              </List_Child>
              <List_Child>
                <Item>
                  <Item_Title variant="h4">千葉オフィス（千葉県船橋市）</Item_Title>
                  <Item_Body theme={theme} variant="body2">ウィザード千葉オフィスは、2013年7月に都内から船橋へ移転して参りました。移転直後から、市内の専門学校から募集があり、採用することができました。このようなことも、地域密着だからこそ可能なことであり、船橋に大きな魅力を感じています。今後は、近隣の企業様と協力して、地域IT化のお役に立てればと思っております。是非、お気軽に声を掛けて頂きたく存じます。</Item_Body>
                </Item>
              </List_Child>
            </List_Parent>
          </List>
        </Main>
      </Container>
    </Section>
  </Root>
)

const Root = styled.div``

const Container = styled.div`
  margin-top: 48px;
`

const Head = styled.div`
  position: relative;
  padding: 64px 0;
`

const Background = styled(ParticleBackground)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Lead = styled(Typography)`
  position: relative;
  text-align: center;
  font-weight: bold;
  @media ${mq.and(tabletS)} {
    text-align: left;
    padding: 0 24px;
    br {
      display: none;
    }
  }
  @media ${mq.and(mobile)} {
    text-align: left;
    padding: 0 16px;
    br {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 48px;
  @media ${mq.and(tabletS)} {
    margin-top: 80px;
  }
  @media ${mq.and(mobile)} {
    margin-top: 64px;
  }
`

const List = styled.div`
`

const List_Parent = styled.ul`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    margin: 0 -16px;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin: -32px auto 0;
  }
`

const List_Child = styled.li`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
    padding: 0 16px;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 32px;
  }
`

const Item = styled.div``

const Item_Title = styled(Typography)``

const Item_Body = styled(Typography)`
  margin-top: 20px;

  a {
    font-weight: bold;
    color: ${props => getThemeColor('primary.light', props.theme)};
    text-decoration: underline;
  }
`

export default ThemeConsumer(CompanyCSV)
